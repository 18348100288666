@if (isAuthenticated) {
<ng-container>
  <div class="main-container" data-testid="main-container">
    <div [ngClass]="{
          'app-container-mobile':
            responsiveService.deviceMobile() ||
            responsiveService.deviceTablet(),
          'app-container-web': responsiveService.deviceDesktop(),
        }" data-testid="router-outlet-wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
} @else {
<div class="non-login-container" data-testid="non-login-container">
  <router-outlet></router-outlet>
</div>
}

@if (loaderService.isLoading$ | async) {
<div class="spinner-overlay">
  <mat-spinner diameter="50"></mat-spinner>
</div>
}