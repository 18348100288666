import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { UserInfoService } from '../services/user-info/user-info.service';

export interface ITokenPayload {
  accessToken: string;
  idToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class TokenResolver {
  private userInfoService = inject(UserInfoService);


  resolve(): Observable<unknown> {
    let payload: ITokenPayload = {
      accessToken: '',
      idToken: '',
    };
    const tokenData = localStorage.getItem('okta-token-storage');
    if (tokenData && typeof tokenData == 'string') {
      const idToken = JSON.parse(tokenData)?.idToken.idToken;
      const accessToken = JSON.parse(tokenData)?.accessToken.accessToken;
      payload = {
        accessToken: accessToken,
        idToken: idToken,
      };
    }

    const token = sessionStorage.getItem('user-logged-in')
    if (token) {
      return new Observable(observer => {
        observer.next(token);
        observer.complete();
      });
    }

    return this.userInfoService.getAccessToken(payload).pipe(
      map((response: any) => {
        sessionStorage.setItem('user-logged-in', 'true');
      }),
      catchError(error => {
        if (error) {
          this.userInfoService.logout();
        }
        return error;
      })
    );
  }
}
