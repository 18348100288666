import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackbarService } from '../services/snackbar.service';
import { ERROR } from '../shared/const/snackbar.const';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private snackbarService = inject(SnackbarService);
  private router = inject(Router);

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  durationInSeconds = 5;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const excludedApis = ['/pnr-retrieval/v1'];

        if (error.status !== 401) {
          // Check if the request URL matches any of the excluded APIs
          const shouldRedirect = !excludedApis.some((api) => request.url.includes(api));
          if (shouldRedirect) {
            this.router.navigateByUrl('error');
          }
        }
        // List of API endpoints to exclude from redirection

        return throwError(() => of(error));
      })
    );
  }
}
