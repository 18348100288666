export const environment = {
  production: false,
  baseURL:'https://dev-flightload.adminmodules.etihad.ae/ada-services/flightloadadmin',
  OKTAClientID: '0oa25pog09fGkSw260h8',
  OKTAIssuer: 'https://etihad.oktapreview.com',
  OKTArediRectUri: 'https://dev-flightload.adminmodules.etihad.ae/login/callback',
  coreDataURL: 'https://test.etihad.com',
  rmToolCookieName: 'rm-tool-dev-jwt',
  env: 'DEV',
  secretKeyEmail: 'rev-man-email-key',
};