import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { LoginComponent } from './components/login/login.component';
import { Routes } from '@angular/router';
import { TokenResolver } from './resolver/token.resolver';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'revenue-management',
    pathMatch: 'full',
  },
  {
    path: 'revenue-management',
    loadChildren: () => import('./revenue-management/revenue-management-routing.module').then((m) => m.RevenueManagementRoutingModule),
    canActivate: [OktaAuthGuard],
    resolve: { TokenResolver },
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'error',
    loadComponent: () => import('./components/error-page/error-page.component').then((m) => m.ErrorPageComponent),
  },
];
