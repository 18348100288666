import { Component, OnInit, inject } from '@angular/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { filter, map } from 'rxjs';
import { LoaderService } from './services/loader/loader.service';
import { ResponsiveService } from './services/responsive/responsive.service';
import { UserInfoService } from './services/user-info/user-info.service';
import { isDefined } from './shared/utils/is-defined';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public oktaAuth: OktaAuth = inject(OKTA_AUTH);
  private oktaStateService = inject(OktaAuthStateService);
  public userInfoService = inject(UserInfoService);
  public loaderService = inject(LoaderService);
  public responsiveService = inject(ResponsiveService);
  isAuthenticated = false;
  ngOnInit() {
    this.oktaStateService.authState$
      .pipe(
        filter((s: AuthState) => !!s),
        filter(isDefined),
        map((s: AuthState) => s.isAuthenticated ?? false)
      )
      .subscribe(async (res) => {
        if(res){
          this.isAuthenticated = true
          this.userInfoService.userInfoDetails = await this.oktaAuth.getUser();
          this.userInfoService.isUserInfoUpdated$.next(true);
        }
      });
  }
}
